const CONFIGURATION = {
    defaultTheme: 'mipasa-light',
    authorizedDomains: ['localhost'],
    apps: {
        mipasa: {
            enabled: true,
            url: 'https://localhost:3000',
        },
        unbounded: {
            enabled: true,
            url: 'https://localhost:3000',
        },
        papillon: {
            enabled: true,
            url: 'https://localhost:3000',
        },
        crypto: {
            enabled: false,
        },
        dpasa: {
            enabled: false,
        },
    },
    oidc: {
        github: true,
        google: true,
        ethereum: true,
    },
    sentry: {
        enabled: false,
    },
};
export default CONFIGURATION;
